import  React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import Seo from '../components/seo';
import ListBrand from '../components/list-brand';
import ListLink from '../components/list-link';
import Image from '../components/Image';

const CatalogPage = () => (
    <Layout page="contact-page">
      <Seo title="CATALOG"/>
      <section className="page-head">
        <ListBrand/>
        <ListLink/>
        <h2 className="main-title"><span>CATALOG</span></h2>
      </section>
      <section className="page-content full-layout">
        <div className="main-content">
          <div className="content-block">
            <h3 className="sub-title"><span>カタログ一覧</span></h3>
          </div>
          <div className="content-block">
            <a className="btn pdf full" href="/update/pdf/andersen.pdf" target="_blank">
              アンデルセンストーブ カタログ ダウンロード
            </a>
            <a className="btn pdf full mt-5" href="/update/pdf/accessory.pdf" target="_blank">
              アクセサリ カタログ ダウンロード
            </a>
            <a className="btn pdf full mt-5" href="/update/pdf/chimney-pamphlet.pdf" target="_blank">
              SCS匠 パンフレット ダウンロード
            </a>
            <a className="btn pdf full mt-5 md:px-8 py-6 mb-14" href="/update/pdf/chimney-price.pdf" target="_blank">
              SCS匠 煙道部材価格表 ダウンロード
            </a>
          </div>
        </div>
      </section>

      <section className="bread">
        <ul>
          <li>
            <Link to="/">
              <Image filename="ico/home.png" />
            </Link>
          </li>
          <li>カタログ一覧</li>
        </ul>
      </section>
    </Layout>
);

export default CatalogPage;
